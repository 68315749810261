<template>
  <div>
    <div v-if="staff">
      <div class="prop">
        FIO: {{ staff.user.firstName }} {{ staff.user.lastName }}
      </div>
      <div class="prop">Email: {{ staff.user.email }}</div>
      <div class="prop">DateOfBirth: {{ staff.dateOfBirth }}</div>
      <div class="prop">Phone: {{ staff.phone }}</div>
      <div class="prop">City: {{ staff.city }}</div>
      <div class="prop">Timezone: {{ staff.timezone }}</div>
      <Can do="read" :on="staff?.staffRate">
        <div class="prop">
          <b>Rate:</b> {{ staff?.staffRate?.value ?? '-' }}
        </div>
        <div class="prop">
          <b>Night rate:</b> {{ staff?.staffRate?.nightValue ?? '-' }}
        </div>
      </Can>
      <div v-if="$can('update', 'staffRate')">
        <div class="prop">
          <div>
            <label for="rate">Set rate:</label>
            <a-input
              id="rate"
              type="number"
              v-model:value="staff.staffRate.value"
              class="rate-input"
            ></a-input>
          </div>
          <br />
          <div>
            <label for="rate">Set night rate:</label>
            <a-input
              id="rate"
              type="number"
              v-model:value="staff.staffRate.nightValue"
              class="rate-input"
            ></a-input>
          </div>
          <a-button class="prop save-btn" @click="handleRateUpdate"
            >Save</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { subject } from '@casl/ability'
import { paramsForServer } from 'feathers-hooks-common'
import { mapActions } from 'vuex'

export default {
  name: 'EditProfile',
  data() {
    return {
      staff: undefined,
      newRate: undefined,
      newNightRate: undefined,
    }
  },
  async created() {
    // Thats awful, it should be /get, but it not works
    const { data } = await this.$store.dispatch(
      'staff/find',
      paramsForServer({
        joins: ['user', 'staffRate'],
        paginate: false,
        query: {
          id: this.$route.query.staffId,
        },
      })
    )

    const [staff] = data
    if (staff?.staffRate) {
      staff.staffRate = subject('staff-rates', staff.staffRate)
    }

    this.staff = staff
  },
  methods: {
    ...mapActions('staff-rates', {
      patchRate: 'patch',
    }),
    async handleRateUpdate() {
      const { id, value, nightValue } = this.staff.staffRate

      try {
        await this.patchRate([
          id,
          {
            nightValue,
            value,
          },
        ])

        alert('Done!')
      } catch (error) {
        alert(error)
      }
    },
  },
}
</script>

<style scoped>
.prop {
  margin: 1rem;
}

.rate-input {
  margin-left: 1rem;
  margin-right: 1rem;
  min-width: 5rem;
  width: 5rem;
}

.save-btn {
  margin-top: 1rem;
  margin-left: 0rem;
  width: 4rem;
}
</style>
