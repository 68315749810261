<template>
  <div class="aps-wrap flex-column">

    <div class="aps-head">
      <div class="title1 mb-8">{{$t('edit-user.personal_profile')}}</div>
      <MiniTabs :tabs="tabs" :tabName="preOpenData.tabName" @openTab="openTab"></MiniTabs>
    </div>

    <EditProfile v-if="currentTab.id === 5413" />

    <!-- <StudentTableWithCard
        v-else-if="currentTab.id === 5412"
        :key="412"
        :options="tabs[1]"
        :preOpen="preOpenData"
        :openable="false"
    ></StudentTableWithCard> -->
  </div>
</template>

<script>
import "@/assets/styles/transitions.scss";
import MiniTabs from "@/components/students/card/MiniTabs";
import EditProfile from "@/components/users/EditProfile";

export default {
  name: "users",
  components: {
    EditProfile,
    MiniTabs
  },
  data() {
    return {
      tabs: [
        {
          id: 5413,
          name: 'overview',
          title: 'Overview',
          description: 'Overview',
          count: 0,
          service: 'users',
          params: {
            joins: [{ name: 'staff', childs: ['staffRate'] }]
          },
          component: EditProfile
        },
        // {
        //   id: 5414,
        //   name: 'students',
        //   title: 'Students',
        //   description: 'Students',
        //   // statusList: ['interview_booking', 'interview_booked', 'interview_attended', 'interview_missed'],
        //   cardTabs: ['applicant_info', 'payment_info','payment_history'],
        //   count: 0,
        //   // service: 'students',
        //   // schema: 'paymentView',
        //   component: Students
        // },
      ],
      currentTab: {},
      preOpenData: {},
    }
  },
  created() {
    this.preOpenData = {...this.$route.query};
  },
  methods: {
    openTab(tab) {
      this.currentTab = tab;
    },
  },
}
</script>

<style>

</style>